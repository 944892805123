import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SEO from "../component/SEO";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <SEO
        title="This page doesn't exist (404) - Calcuvate"
        description="404 Error – Looks like you're lost! Navigate back to Calcuvate for smart, reliable, and user-friendly calculators."
        image="https://www.calcuvate.com/socials/social-icon.jpg"
        url="https://www.calcuvate.com/"
      />
      <div className="not-found-pg">
        <div className="">
          <h1 className="primary-fonts">404</h1>
          <p>Page Not Found ⚠️</p>
          <span>we couldn't find the page you are looking for</span>
          <div>
            <button onClick={() => navigate("/")}> Back Home</button>
          </div>
        </div>
      </div>
    </>
  );
};
export default NotFoundPage;
