import React from "react";
import { Link } from "react-router-dom";
import Layout from "../component/Layout";
import SEO from "../component/SEO";
import MostLIkeBlogs from "../component/MostLIkeBlogs";
import Icons from "../Assets/Icons";
export default function OurBlogs() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SEO
        title="Blogs - Calcuvate"
        description="Browse Calcuvate’s collection of blogs covering financial calculators, industry trends, and helpful advice. "
        image="https://www.calcuvate.com/socials/social-icon.jpg"
        url="https://www.calcuvate.com/blogs"
      />
      <Layout>
        <div className="container">
          <section className="multi-blog-contain">
            <div className="content-cover-box">
              <div className="multi-blog-data">
                <div className="multi-blog-title">
                  <h2 className="primary-font mt-0 mb-1">Blogs</h2>
                  <p>
                    Read the latest blogs on financial calculators, planning
                    strategies, and tips at Calcuvate.
                  </p>
                </div>
                <div className="blog-multi-hightlight">
                  <div className="blog-multi-cmn">
                    <div className="blogs-box border-bottom ">
                      <div className="blog-date">
                        Jennifer Brien<span>/03 Nov, 2024</span>
                      </div>
                      <h2>
                        Master Repayment Strategies With An Online Loan
                        Calculator
                      </h2>
                      <p>
                        If you're looking for a personal loan, a mortgage, or an
                        auto loan, an online loan calculator can help you
                        understand what the specifications are. Some calculators
                        even allow you to make comparisons on different loan
                        processes. Therefore, you can see how changing the
                        interest rate or loan amount affects your monthly
                        payments.
                      </p>
                      <Link to="/master-repayment-strategies-with-an-online-loan-calculator">
                        Read More
                        {Icons.BlogArrow}
                      </Link>
                    </div>
                  </div>
                  <div className="blog-multi-cmn">
                    <div className="blogs-box border-bottom ">
                      <div className="blog-date">
                        Jennifer Brien<span>/13 Oct, 2024</span>
                      </div>
                      <h2>Manage Finances With Online Payroll Calculator</h2>
                      <p>
                        Handling multiple expenses together is a tough task for
                        every individual nowadays. Therefore, technology has
                        become an extremely important element to keep everything
                        on track.
                      </p>
                      <Link to="/manage-finances-with-online-payroll-calculator">
                        Read More
                        {Icons.BlogArrow}
                      </Link>
                    </div>
                  </div>
                  {/* </div> */}

                  {/* <div className="blog-multi-comn"> */}
                  <div className="blog-multi-cmn">
                    <div className="blogs-box border-bottom ">
                      <div className="blog-date">
                        Jennifer Brien<span>/03 Oct, 2024</span>
                      </div>
                      <h2>Tips for Using a Loan Calculator</h2>
                      <p>
                        It simplifies your calculations by assisting you in
                        having a better view of your financial commitment.
                        Moreover, it encourages you to make appropriate
                        financial decisions.
                      </p>
                      <Link to="/tips-for-using-a-loan-calculator">
                        Read More
                        {Icons.BlogArrow}
                      </Link>
                    </div>
                  </div>
                  <div className="blog-multi-cmn">
                    <div className="blogs-box border-bottom ">
                      <div className="blog-date">
                        Jennifer Brien<span>/25 Sep, 2024</span>
                      </div>
                      <h2>Use Salary Calculator: Calculate Your Worth</h2>
                      <p>
                        This online calculator helps you estimate your salary,
                        net income, or take-home pay after taxes and benefits.
                        Our easy-to-use website is open to everyone.
                      </p>
                      <Link to="/use-salary-calculator-calculate-your-worth">
                        Read More
                        {Icons.BlogArrow}
                      </Link>
                    </div>
                  </div>
                  <div className="blog-multi-cmn">
                    <div className="blogs-box border-bottom ">
                      <div className="blog-date">
                        Jennifer Brien<span>/10 Sep, 2024</span>
                      </div>
                      <h2>The Importance Of Online Scientific Calculator</h2>
                      <p>
                        It makes them valuable in subjects such as mathematics,
                        physics, and engineering. Moreover, these calculators
                        solve even more difficult equations.
                      </p>
                      <Link to="/the-importance-of-online-scientific-calculator">
                        Read More
                        {Icons.BlogArrow}
                      </Link>
                    </div>
                  </div>

                  {/* new blogs add  */}

                  <div className="blog-multi-cmn">
                    <div className="blogs-box border-bottom ">
                      <div className="blog-date">
                        Jennifer Brien<span>/30 Aug, 2024</span>
                      </div>
                      <h2>Mortgage Calculator: Tracking Monthly Payments</h2>
                      <p>
                        You can estimate your monthly mortgage payment and other
                        related costs with the help of the Mortgage Calculator.
                      </p>
                      <Link to="/mortgage-calculator-tracking-monthly-payments">
                        Read More
                        {Icons.BlogArrow}
                      </Link>
                    </div>
                  </div>
                  <div className="blog-multi-cmn">
                    <div className="blogs-box border-bottom ">
                      <div className="blog-date">
                        Jennifer Brien<span>/05 Aug, 2024</span>
                      </div>
                      <h2>
                        How Online Math Calculators Simplify Students Life?
                      </h2>
                      <p>
                        An online math calculator is a valuable resource. It
                        focuses on understanding these concepts rather than
                        getting bored with calculations.
                      </p>
                      <Link to="/how-online-math-calculators-simplify-students-life">
                        Read More
                        {Icons.BlogArrow}
                      </Link>
                    </div>
                  </div>
                  <div className="blog-multi-cmn">
                    <div className="blogs-box border-bottom ">
                      <div className="blog-date">
                        Jennifer Brien<span>/23 Jul, 2024</span>
                      </div>
                      <h2>
                        The Role Of Free Online Calculators In Health & Fitness
                      </h2>
                      <p>
                        Online calculators are digital tools accessible on our
                        website that help users perform calculations related to
                        health and fitness.
                      </p>
                      <Link to="/the-role-of-free-online-calculators-in-health-fitness">
                        Read More
                        {Icons.BlogArrow}
                      </Link>
                    </div>
                  </div>
                  <div className="blog-multi-cmn">
                    <div className="blogs-box border-bottom ">
                      <div className="blog-date">
                        Jennifer Brien<span>/06 Jul, 2024</span>
                      </div>
                      <h2>BMI Calculator: A Path To Maintain Health</h2>
                      <p>
                        The Body Mass Index (BMI) calculation helps categorize
                        individuals based on their weight according to their
                        height.
                      </p>
                      <Link to="/bmi-calculator-a-path-to-maintain-health">
                        Read More
                        {Icons.BlogArrow}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}
