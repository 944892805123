import React from "react";
import Icons from "../Assets/Icons";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <section className="footer-sec">
        <div className="container position-relative">
          <div className="footer-icon">
            <img
              src={require("../Assets/Images/footer-icon.svg").default}
              alt=""
            />
          </div>
        </div>

        <div className="footer-contin">
          <div className="footer-logo">{Icons.LogoMain}</div>
          <p>
            &apos;Calculate&apos; offers free online tools to simplify your
            calculations.Our calculators
            <br /> cover finance, science, mathematics, fitness, and daily life
            calculations, helping
            <br /> you save valuable time and effort.
          </p>
          <ul>
            <li className="primary-font">
              <Link to="/about-us">About Us</Link>
            </li>
            <li className="primary-font">
              <Link to="/blogs">Blogs</Link>
            </li>
            <li className="primary-font">
              <Link to="/contact-us">Contact US </Link>
            </li>
            <li className="primary-font">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li className="primary-font">
              <Link to="/terms-of-use">Terms of Use</Link>
            </li>
          </ul>
        </div>
        <div className="footer-all-rights">
          <p>2024 Calcuvate. All Rights Reserved</p>
        </div>
      </section>
    </>
  );
}
